





























import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { client, uniqueId, TagHit, Tag, Hit } from "~/config/algolia"
import { state } from "@/store"
import deburr from "lodash/deburr"

type locationItem = {
  header?: string
  id?: number | string
  name?: string
  real_name?: string
  code?: string
  uniqueId?: string
  group?: string
}
@Component({})
export default class SelectLocation extends Vue {
  @Prop({ default: [] }) selection!: string[]
  items: locationItem[] = []
  search = ""
  districtItems: locationItem[] = [{ header: "Quartier" }]
  cityItems: locationItem[] = [{ header: "Ville" }]
  departmentItems: locationItem[] = [{ header: "Département" }]

  @Watch("search")
  async onSearch(value = "") {
    await this.fetchLocationsFromAlgolia(value)
    this.items = [
      ...this.districtItems.sort(
        (a: locationItem, b: locationItem) =>
          a.real_name?.localeCompare(b.real_name || "") || 1
      ),
      ...this.cityItems.sort(
        (a: locationItem, b: locationItem) =>
          a.real_name?.localeCompare(b.real_name || "") || 1
      ),
      ...this.departmentItems.sort(
        (a: locationItem, b: locationItem) =>
          a.real_name?.localeCompare(b.real_name || "") || 1
      ),
    ]
  }

  @Watch("selection")
  setSelection() {
    const itemsUniqIds = this.items.map((item) => item.uniqueId)
    this.selection.map((tag) => {
      if (!itemsUniqIds.includes(tag)) {
        if (tag.split(":")[1] != "") this.onSearch(tag.split(":")[1])
      }
    })
  }

  mounted() {
    //const selection = () => this.selection // little hack so that this.selection is accessible to the getters and setters below

    state.tags.onceLoaded((tags) => {
      this.districtItems = [
        ...this.districtItems,
        ...tags.districts.map((district: Hit<Tag>) => ({
          id: district.id,
          name: district.name,
          real_name: district.name,
          uniqueId: uniqueId("Tag", district.id),
          group: "Quartier",
        })),
      ]
    })
    this.setSelection()
  }

  updateSelection(value: string[]) {
    this.search = ""
    this.selection.length = 0
    this.selection.push(...value)
  }

  async fetchLocationsFromAlgolia(query: string) {
    const queries = [
      {
        indexName: "Department",
        query,
      },
      {
        indexName: "City",
        query,
      },
    ]
    const { results } = await client.multipleQueries(queries)
    if (query)
      queries.forEach(({ indexName }, i) => {
        const hits = results[i].hits as TagHit[]
        for (const hit of hits) {
          const type = hit.code
            ? "Département"
            : hit.sub_category_id
            ? "Quartier"
            : "Ville"
          const item = {
            id: hit.id || hit.code,
            uniqueId: uniqueId(indexName, hit.id || hit.code),
            name: hit.name,
            real_name: hit.real_name || hit.name,
            group: type,
            code: hit.zipcode || hit.code || hit.name,
          } as locationItem
          if (hit.code) this.departmentItems.push(item)
          else if (hit.sub_category_id) this.districtItems.push(item)
          else this.cityItems.push(item)
        }
      })
  }
  customFilter(item: locationItem, queryText: string) {
    if (item.header) return item
    const name = deburr(item.name?.toLowerCase())
    const realName = deburr(item.real_name?.toLowerCase())
    const zipcode = deburr(item.code?.toLowerCase())
    let searchText = deburr(queryText.toLowerCase())
    let searchArr = null
    if (searchText.search(/\d{2}00/) > -1) {
      searchArr = searchText.replace(/\d{2}00/, "")
    } else if (searchText.search(/\d{2}0/) > -1) {
      searchArr = searchText.replace(/\d{2}0/, "")
    }
    return (
      (searchArr != null &&
        realName &&
        realName.indexOf(searchArr + "eme") > -1) ||
      (searchArr != null &&
        realName &&
        realName.indexOf(searchArr + "er") > -1) ||
      (name && name.indexOf(searchText) > -1) ||
      (realName && realName.indexOf(searchText) > -1) ||
      (zipcode && zipcode.indexOf(searchText) > -1)
    )
  }
}
